/* eslint-disable no-unused-vars */
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { FaTimes, FaSpinner } from "react-icons/fa";
import { useSnackbar } from "react-simple-snackbar";
import { Helmet } from "react-helmet-async";
//Components
import Nav from "../components/Nav";
import GLOBE from "vanta/dist/vanta.globe.min";

export default function Home() {
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const navigate = useNavigate();
  const [isView, setIsView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activated, setActivated] = useState(false);
  const [name, setName] = useState("Dave Wallace");
  const [phone, setPhone] = useState("214.466.6113");
  const [email, setEmail] = useState("dwallace@nxtsys.com");

  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);
  const myViewer = useRef(null);

  useEffect(() => {
    if (!vantaEffect && myRef.current) {
      setVantaEffect(
        GLOBE({
          el: myRef.current,
          mouseControls: true,
          touchControls: false,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          backgroundAlpha: 1,
          backgroundColor: 0x23153c,
          speed: 0.1,
          blurFactor: 1,
          zoom: 1,
          speedLimit: 0.1,
        })
      );
    }
    if (vantaEffect) {
      vantaEffect.onMouseMove = (x, y) => {
        const c = vantaEffect.camera;
        if (!c.oy) {
          c.oy = c.position.y;
          c.ox = c.position.x;
          c.oz = c.position.z;
        }
        const ang = Math.atan2(c.oz, c.ox);
        const dist = Math.sqrt(c.oz * c.oz + c.ox * c.ox);
        const tAng =
          ang + (x - 0.5) * 0.7 * (vantaEffect.options.mouseCoeffX || 1);
        c.tz = dist * Math.sin(tAng);
        c.tx = dist * Math.cos(tAng);
        c.ty = c.oy + (y - 0.5) * 25 * (vantaEffect.options.mouseCoeffY || 1);
        if (!vantaEffect.rayCaster) {
          // vantaEffect.rayCaster = new THREE.Raycaster()
        }
        vantaEffect.rcMouseX = x * 2 - 1;
        vantaEffect.rcMouseY = -x * 2 + 1;
      };
    }
    return () => {
      if (vantaEffect) {
        vantaEffect.destroy();
      }
    };
  }, [vantaEffect, isView]);

  useEffect(() => {
    if (
      document.getElementById("warpper") &&
      document.getElementById("ar-btn")
    ) {
      document.getElementById("warpper").addEventListener("load", function () {
        for (const material of document.getElementById("warpper").model
          .materials) {
          material.occlusionTexture.setTexture(null);
        }
        setLoading(false);
        document.getElementById("ar-btn").click();
      });
      document
        .getElementById("warpper")
        .addEventListener("ar-status", function (event) {
          if (event.detail.status === "not-presenting") {
            setIsView(false);
          }
          if (event.detail.status === "failed") {
            setIsView(true);
          }
        });

      if (navigator.userAgent.indexOf("Chrome") === -1) {
        document.getElementById("warpper").activateAR();
        setTimeout(() => {
          setActivated(true);
        }, 100);
      }
    }
  }, [isView]);

  useEffect(() => {
    if (activated && isView) {
      setIsView(false);
    }
  }, [activated, isView]);

  const handleVCF = (e) => {
    e.preventDefault();
    openSnackbar("Saved contact file to device.");
    const file = new Blob(
      [
        `BEGIN:VCARD
VERSION:3.0
N:${name};${name};;;
FN:${name} ${name}
TITLE:President;
EMAIL;type=INTERNET;type=pref:${email}
TEL;type=MAIN:President
TEL;type=CELL;type=VOICE;type=pref:01716121009
ADR;type=WORK;type=pref:;;;Bangladesh;;;
END:VCARD
`,
      ],
      { type: "text/vcard;charset=utf-8" }
    );
    let a = document.createElement("a");
    a.download = `${name}.vcf`;
    a.href = URL.createObjectURL(file);
    const reader = new FileReader();
    reader.fileName = file.name;
    if (navigator.userAgent.match("CriOS")) {
      reader.onloadend = (e) => {
        window.open(reader.result);
      };
      reader.readAsDataURL(file);
    } else if (navigator.userAgent.match(/iPad|iPhone|Android/i)) {
      // reader.onload = (e) => {
      //   window.location.href = reader.result;
      // };
      // reader.readAsDataURL(file);
      saveAs(file, `${name}.vcf`, true);
    } else {
      saveAs(file, `${name}.vcf`, true);
    }
    openSnackbar("Saved contact file to device.");
  };

  const onOpenViewer = () => {
    setLoading(true);
    vantaEffect.destroy();
    setVantaEffect(0);
    setIsView(true);
  };
  if (isView) {
    return (
      <StyledSceneViewer>
        <model-viewer
          ref={myViewer}
          id="warpper"
          src="./models/BusinessCard_DaveWallace_LIT2.glb"
          ar
          ar-scale="auto"
          camera-controls
          camera-target="0m 0m 0m"
          disable-zoom
          exposure="1"
          environment-image="../models/lightroom_14b.hdr"
          ar-modes="webxr quick-look scene-viewer"
          camera-orbit="0deg 50deg 3.5m"
          alt="A 3D model of an astronaut"
          ios-src="./models/nxtsys-business-card.usdz"
          xr-environment
        >
          <div className="icon" onClick={() => setIsView(false)}>
            <FaTimes size={20} color="black" />
          </div>
          {loading && (
            <div className="loading">
              <FaSpinner size={50} color="black" className="spinner-icon" />
            </div>
          )}
          {/* <Button
            type="submit"
            primary
            onClick={() => null}
            className="export-btn"
          >
            Export
          </Button>
          */}
          <button slot="ar-button" id="ar-btn">
            👋 Activate AR
          </button>
        </model-viewer>
      </StyledSceneViewer>
    );
  }

  return (
    <StyledHome ref={myRef}>
      <Helmet>
        <title>NXTSYS - Dave</title>
        <meta name="description" content="NXTSYS Business Card - Dave " />
      </Helmet>
      <div className="navWarpper">
        <Nav />
      </div>

      <div className="detailsWarpper">
        <h1>{name}</h1>
        <h6>President</h6>
        <p>
          <span>
            <a href={`tel:${phone}`}>{phone}</a>{" "}
          </span>
          &nbsp; • &nbsp;
          <span>
            <a href={`mailto:${email}`}>{email}</a>
          </span>
        </p>
      </div>
      <div className="btnWarpper">
        <Button type="submit" primary onClick={handleVCF}>
          Connect
        </Button>
        <Button type="submit" onClick={() => onOpenViewer()}>
          View Card
        </Button>
      </div>
    </StyledHome>
  );
}

const StyledSceneViewer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  .loading {
    color: black;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 45%;
    left: 50%;
    .spinner-icon {
      animation: spin infinite 5s linear;
    }
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
  #warpper {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    bottom: 10%;
    right: 0;
    left: 0;
    .icon {
      position: absolute;
      top: 20px;
      left: 20px;
      cursor: pointer;
    }
    #ar-btn {
      opacity: 0;
    }
    .export-btn {
      position: absolute;
      bottom: 0;
      left: 35%;
      @media (max-width: 767px) {
        left: 25%;
      }
    }
  }
`;
const StyledHome = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  .btnWarpper {
    flex: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    justify-content: center;
    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
    }
  }
  .navWarpper {
    display: flex;
    flex: 3;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    align-items: center;
  }
  .detailsWarpper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
    }
    h1 {
      color: #ffffff;
      font-size: 98px;
      line-height: 110px;
      font-weight: 300;
      @media (max-width: 767px) {
        font-size: 38px;
        line-height: 40px;
      }
    }
    h6 {
      font-size: 48px;
      line-height: 56px;
      font-weight: 200;
      color: #ffffff;
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 30px;
      }
    }
    p {
      font-size: 36px;
      line-height: 42px;
      margin-top: 10px;
      font-weight: 600;
      color: #ffffff;
      text-decoration: none;
      span {
        text-decoration: underline;
      }
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  h2 {
    font-size: 5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.2rem;
  }
`;
