/* eslint-disable no-unused-vars */
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";
import logo2 from "../assets/logo.png";

export default function Nav() {
  const [vw, setVw] = useState(window.innerWidth);

  useEffect(() => {
    setVw(window.innerWidth);
    window.addEventListener("resize", () => {
      setVw(window.innerWidth);
    });
  }, []);

  return (
    <StyledNav>
      <a href="https://nxtsys.com" target="_blank" rel="noopener noreferrer">
        <img src={logo2} className="logo" alt="Signature NFT Logo" />
      </a>
    </StyledNav>
  );
}

const StyledNav = styled.nav`
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  width: 100%;

  z-index: 10;
  .logo {
    width: 350px;
    @media (max-width: 767px) {
      width: 200px;
    }
  }
`;
